import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import axios from 'axios'
import { LocalStorage } from 'quasar'
import routerData from '../config/router-config'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login: false,
    needRefreshList: 0,
  },
  mutations: {
    successLogin(state, payload) {
      state.login = true
      console.log('payload', payload)
      console.log(router)
      let routerArr = []
      for (let index = 0; index < payload.length; index++) {
        let children = [];
        if (payload[index].children.length > 0) {
          for (let j = 0; j < payload[index].children.length; j++) {
            children.push({
              name: payload[index].children[j].name || '',
              path: payload[index].children[j].path,
              component: () => import(`../views/application/${payload[index].children[j].component}.vue`),
            })

          }
        }
        routerArr.push({
          path: payload[index].routerPath,
          name: payload[index].routerName,
          component: () => import(`../views/application/${payload[index].component}.vue`),
          children
        })

      }
      console.log('routerArr', routerArr)
      router.addRoutes(routerArr);
    },
    refreshList(state, result) {
      state.needRefreshList = result
    }
  },	
  
  actions: {
    authLogin({ commit }, query) {//&appname=qywx_app15&source=qywx
      return new Promise((resolve) => {
        console.log('query', query)
        if(query.source){
          LocalStorage.set('appSource', query.source);
        }
        if(query.appname){
          LocalStorage.set('appName', query.appname);
        }
        // LocalStorage.set('token','916f09f6-98f6-4c86-8888-192925801cc1');
        let postData = {
          // "appName": query.appname || "qywx_app4",
          "appName": query.appname || "ding_app1",
          "code": query.code || "yMGPlMcQavfqdIVmBgEGhreaoENyz3UOBnGz-wZ4MH8",
          //   "code": query.code || "XwJ4ZXuhC-ENzZioGOYYDk42V2IwKW_AOF4KhPzHx0I",
          // "source": query.source || "qywx"
          "source": query.source || "ding"  
        }
        // console.log(query.code)
        // return
        // LocalStorage.set('token', 'c30f1d7e-b8ca-46ff-838d-db3b23d43508');
         // LocalStorage.set('token', 'a8dd38ca-d3af-4d19-9276-9549da15e674');
        //297687b9-2ae6-4e1a-b276-45c1733a3077
        // LocalStorage.set('token', '');
        //    LocalStorage.set('userOwnerShops', '');
        //   LocalStorage.set('ownerShopNames', '');
        //  LocalStorage.set('jobnumber', '37');
        //      LocalStorage.set('userName','施森森');
        //  LocalStorage.set('userMobile', '15868729163');
        //   LocalStorage.set('userOwnerShops', 'BT808Z051');
        //  LocalStorage.set('ownerShopNames', '嘉兴禾兴路报喜鸟专卖店');
        //  LocalStorage.set('code', query.code);
        //LocalStorage.set('brandCode','ZBXN');
        axios.post('/auth/ompLogin', postData).then((response) => {
         // console.log('axios', response.data.data.hrid);
          if (response.data.code == 200) {
            LocalStorage.set('token', response.data.data.access_token);
            // LocalStorage.set('userMobile', response.data.data.mobile);
            // LocalStorage.set('userIsGuide', response.data.data.isGuide);
            // LocalStorage.set('userDeptIds', response.data.data.deptIds);
            LocalStorage.set('userOwnerShops', response.data.data.ownerShops);
            // LocalStorage.set('ownerShopNames', response.data.data.ownerShopNames);
            // LocalStorage.set('userId', response.data.data.userId);
            LocalStorage.set('agentId', response.data.data.agentId);
            LocalStorage.set('corpId', response.data.data.corpId);
            LocalStorage.set('kdgrp', response.data.data.kdgrp);
            LocalStorage.set('jobnumber', response.data.data.jobnumber);
            // LocalStorage.set('userName', response.data.data.userName);
            // LocalStorage.set('hrid', response.data.data.hrid);
            // LocalStorage.set('ownerShops', response.data.data.ownerShops);
            // LocalStorage.set('ownerShopNames', response.data.data.ownerShopNames);
            // LocalStorage.set('brandCode', response.data.data.brandCode);

            // LocalStorage.set('ownerShops', 'E001Z003,BT731Z051');
            // LocalStorage.set('ownerShopNames', '河田凤凰尚品总部直销厅,报喜鸟杭州庆春银泰景昙路店');

            commit('successLogin', routerData)
            resolve(routerData)

            //  callback(response.data.data);
          } else {
            console.log(response.data)
            commit('successLogin', routerData)
            resolve(routerData)
          }
        }).catch((error) => {
          console.log(error);

        })
     
        
      })

    }
  },
  modules: {
  }
})
