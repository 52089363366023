import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {analysisGlobal} from './lib/bxn-analysis.js'
import './config/axios-config'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './quasar'
import VuePageStack from 'vue-page-stack'
import Vant from 'vant'
import 'vant/lib/index.css'
import storage from "./common/storage";
import PageRouterView from '@/components/PageRouterView.vue'
import * as dd from 'dingtalk-jsapi'; //引入钉钉SDK
Vue.prototype.$dd = dd;
// import requestSignatureAPI from "./common/wxconfig";
import moment from 'moment'
import ElementUI from 'element-ui';
import HighchartsVue from 'highcharts-vue'
import 'element-ui/lib/theme-chalk/index.css';
import { Lazyload } from 'vant';
analysisGlobal(router);//统计
Vue.use(Lazyload, {
  loading:require('./assets/loading.gif'),
  error:require('./assets/error.png'),

});
// console.log(requestSignatureAPI)
Vue.component('PageRouterView',PageRouterView)
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;
Vue.use(VuePageStack, {router});
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(HighchartsVue);
Vue.prototype.$ajaxPrefix={
  consumer:'consumer',
  hr:'hr',
  system:'system',
  worktask:'worktask'
}
Vue.prototype.$storage = storage;
Vue.prototype.$moment = moment;
Vue.prototype.$memberInfo={
//  hhrEmpid:'10000596'//hr人员ID 10016205  10000596
hhrEmpid:'10016205',
placeLoaction:false
}
Vue.filter("perFormat", function (v) {

  if(v){
     
    return Math.floor(v*10000)/100+'%'
  }else{
    return
  }

});
Vue.filter("per45Format", function (v) {

  if(v){
     
    return Math.round(v*100)+'%'
  }else{
    return
  }

});
Vue.filter("dot2Format", function (v) {

  if(v){
     
    return Math.floor(v*100)/100
  }else{
    return
  }

});
Vue.filter("dot0Format", function (v) {

  if(v){
     
    return Math.floor(v)
  }else{
    return
  }

});
Vue.filter("dot045Format", function (v) {

  if(v){
     
    return Math.round(v)
  }else{
    return
  }

});
Vue.filter('nopoint', function(num){
  return parseInt(num)||0;
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');


